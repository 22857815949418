export const URL_API = process.env.NODE_ENV === 'production' ? 'https://api-domicilios.azurewebsites.net'
: "https://api-domicilios.azurewebsites.net"
//http://localhost:21021

export const LST_TIPO_IDENTIFICACION = [
    { value: "Cédula", key: "C" },
    { value: "Pasaporte", key: "P" }
  ];
  
  export const OBJ_TIPO_IDENTIFICACION = {
    "C":{ value: "Cédula",  },
    "P": { value: "Pasaporte" }
  };
  export const LST_SEXOS = [
    { key: "M", value: "Masculino" },
    { key: "F", value: "Femenino" }
  ];

  export const OBJ_SEXOS = {
   "M":{value: "Masculino" },
    "F":{value: "Femenino" }
  }
  
  export const LST_PARENTESCOS_MENORES = [
    "HIJO/A",
    "HERMANO/A",
    "NIETO/A",
    "BIZNIETO/A",
    "TIO/A",
    "SOBRINO/A",
    "CUÑADO/A",
    "PRIMO/A",
    "AMIGO/A",
    "VECINO/A",
    "TUTOR/A",
    "ESPOSO/A",
    "CÓNYUGE",
    "PAREJA",
    "OTRO"
  ];

  export const LST_NACIONALIDADES = [
    "AFGANA",
    "ALBANES",
    "ALEMANA",
    "ANGOLANA",
    "ANTIGUANA",
    "ARGELINA",
    "ARGENTINA",
    "ARMENIO",
    "AUSTRÍACA",
    "BANGLADESÍ",
    "BARBADENSE",
    "BIELORRUSA",
    "BELGA",
    "BELICEÑA",
    "BOLIVIANA",
    "BRASILEÑO",
    "BRITÁNICA",
    "BRUNEANA",
    "BÚLGARA",
    "CABOVERDIANA",
    "CAMBOYANA",
    "CAMERUNESA",
    "CANADIENSE",
    "CHECA",
    "CHILENA",
    "CHINA",
    "CHIPRIOTA",
    "COLOMBIANA",
    "CONGOLEÑA",
    "COREANA",
    "COSTARRICENSE",
    "CROATA",
    "CUBANA",
    "DANESA",
    "DOMINICANA",
    "ECUATORIANA",
    "EGIPCIA",
    "ESCOCESA",
    "ESLOVACA",
    "ESLOVENO",
    "ESTADOUNIDENSE",
    "ESTONIA",
    "ETÍOPE",
    "FILIPINA",
    "FINLANDESA",
    "FRANCESA",
    "GHANESA",
    "GEORGIANO",
    "GRANADINA",
    "GRIEGA",
    "GUATEMALTECA",
    "GUYANESA",
    "HAITIANA",
    "HOLANDESA",
    "HÚNGARA",
    "INDONESIA",
    "HINDÚ",
    "INGLESA",
    "IRANÍ",
    "IRAQUÍ",
    "IRLANDESA",
    "ISLANDESA",
    "ISRAELITA",
    "ITALIANA",
    "JAMAIQUINA",
    "JAPONESA",
    "JORDANA",
    "KAZAKA",
    "KENIATA",
    "KIRIBATIANA",
    "KUWAITÍ",
    "LETONA",
    "LIBANESA",
    "LIBERIANA",
    "LIBIA",
    "LIECHTENSTEINIANA",
    "LITUANA",
    "LUXEMBURGUESA",
    "MACEDONIA",
    "MALASIA",
    "MALTÉS",
    "MARFILEÑA",
    "MARROQUÍ",
    "MEXICANA",
    "MOLDAVA",
    "NICARAGÜENSE",
    "NIGERIANA",
    "NORUEGA",
    "PAKISTANÍ",
    "PANAMEÑA",
    "PARAGUAYA",
    "PERUANA",
    "POLACA",
    "PORTUGUESA",
    "RUSA",
    "SUECA",
    "SUIZA",
    "TAILANDESA",
    "TURCA",
    "URUGUAYA",
    "UZBEKA",
    "VENEZOLANA",
    "VIETNAMITA",
    "AUSTRALIANA",
    "BAHAMEÑA",
    "BAREINÍ",
    "BENINÉS",
    "BOTSUANA",
    "ESPAÑOLA",
    "GALESA",
    "GROENLANDESA",
    "ECUATOGUINEANA",
    "HONDUREÑA",
    "NORIRLANDESA",
    "LESOTENSE",
    "MONAQUESA",
    "NEERLANDÉS",
    "NORCOREANA",
    "OMANÍ",
    "CATARÍ",
    "CENTROAFRICANA",
    "SERBIA",
    "SINGAPURENSE",
    "SALOMONENSE",
    "SIRILANQUESA",
    "SURINAMESA",
    "VANUATUENSE",
    "YEMENÍ",
    "MAURICIANA",
    "MOLDIVIANA",
    "MONGOLA",
    "BIRMANA",
    "NAMIBIA",
    "NEOZELANDESA",
    "NEPALESA",
    "PALESTINA",
    "RUANDESA",
    "RUMANA",
    "SALVADOREÑA",
    "SANMARINENSE",
    "SANCRISTOBALEÑO",
    "SANTALUCENSE",
    "SAUDITA",
    "SENEGALESA",
    "SIERRALEONÉS",
    "SIRIA",
    "SOMALÍ",
    "SUDAFRICANA",
    "TAIWANESA",
    "TIMORENSE",
    "TOGOLESA",
    "UCRANIANA",
    "TUNECINA",
    "SANVICENTINA",
    "YUGOSLAVA"
  ]