import Vue from "vue";
import Vuex from "vuex";

import Ajax from "@/utils/ajax";

Vue.use(Vuex);

export default new Vuex.Store({
  state:{
    branchoffice: {
      nombre:"",
      autoServicioDisponible: true, 
      pruebas: []
    }, 
    listBranchoffice: []
  }, 
  actions: {
    async getTest() {
      try {
        const resp = await Ajax.get(
          "/api/services/app/Domicilio/GetMenuPruebas"
        );
        return Promise.resolve(resp.data.result);
      } catch (error) {
        return Promise.reject(error.response.data.error);
      }
    },

    async getInfoSucursales(context, { data }) {
      try {
        const resp = await Ajax.get("/api/services/app/Sector/GetSucursal", {
          params: data,
        });
        return Promise.resolve(resp.data.result);
      } catch (error) {
        return Promise.reject(error.response.data.error);
      }
    },

    async getSucursales({commit}, { data }) {
      try {
        const resp = await Ajax.get("/api/services/app/Sector/GetSucursales", {
          params: data,
        });
        commit("SET_LIST_BRANCHOFFICE",resp.data.result.filter(x => x.centro))
        return Promise.resolve(resp.data.result);
        
      } catch (error) {
        return Promise.reject(error.response.data.error);
      }
    },

    async getInfoPadron(context, { data }) {
      try {
        const resp = await Ajax.get("/api/services/app/Padron/Get", {
          params: {
            cedula: data,
            token: "81a10b8c-2331-44e3-b125-eb2b78fdfd37",
          },
        });
        return Promise.resolve(resp.data.result);
      } catch (error) {
        return Promise.reject(error.response.data.error);
      }
    },
    async processMeeting(context, { data }) {
      try {
        const resp = await Ajax.post(
          "/api/services/app/Cita/ProcesarCitaInstantanea",
          data
        );
        return Promise.resolve(resp.data.result);
      } catch (e) {
        return Promise.reject(e.response.data.error);
      }
    },
  },
  mutations:{
    SET_BRANCH_OFFICE(state, payload){
      state.branchoffice = payload
    }, 
    SET_LIST_BRANCHOFFICE(state, payload){
      state.listBranchoffice = payload
    }
  }
});
