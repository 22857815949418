import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'sweetalert2/dist/sweetalert2.min.css';
import 'flatpickr/dist/flatpickr.css';
//boostrap 
import { IconsPlugin } from 'bootstrap-vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.use(IconsPlugin)

import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);

//Loading
import Loading from "@/utils/plugins/Loading/loading"
Vue.use(Loading)

import './assets/style.scss'

//filters
import '@/utils/filters'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
