import Loading from "./Loading.vue";

let instance;

export default {
  install(Vue) {
    if (!instance) {
      const LoadingPlugin = Vue.extend(Loading);
      instance = new LoadingPlugin({
        el: document.createElement("div")
      });
    }

    let loading = {
      show() {
        instance.isLoading = true;
        document.querySelector("body").appendChild(instance.$el);
      },
      hide() {
        instance.isLoading = false;
      }
    };
    if (!Vue.$loading) {
      Vue.$loading = loading;
    }

    Vue.prototype.$loading = Vue.$loading;
  }
};
